import {
  Retail,
  BankAndFinance,
  Healthcare,
  Technology,
  Telecoms,
  Insurance,
  Manufacturing,
  Government,
  Education,
  NonProfit,
  Property,
  TransportLogistics,
  EnergyUtilities,
  ProfessionalServices,
  HospitalityTourism,
  Gaming,
  Blockchain,
  MediaEntertainment,
  Pharmaceuticals,
  Automative,
  ECommerce,
  Agriculture,
  Building,
  SpaceDefense,
  FoodBeverage,
} from './sectors_default';

export default [
  Retail,
  BankAndFinance,
  Healthcare,
  Technology,
  Telecoms,
  Insurance,
  Manufacturing,
  Government,
  Education,
  NonProfit,
  Property,
  TransportLogistics,
  EnergyUtilities,
  ProfessionalServices,
  HospitalityTourism,
  Gaming,
  Blockchain,
  MediaEntertainment,
  Pharmaceuticals,
  Automative,
  ECommerce,
  Agriculture,
  Building,
  SpaceDefense,
  FoodBeverage,
];
