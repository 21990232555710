import React from 'react';
import { func } from 'prop-types';
import LinkToWebsite from '../LinkToWebsite';
import Signup from '../Signup';
import Login from '../Login';
import SignupClient from '../SignupClient';

function OfflineDrawerScreen({ t }) {
  return (
    <>
      <SignupClient className="m-b-m" t={t} />
      <Signup className="m-b-m" t={t} />
      <Login className="m-b-m tc" t={t} />
      <div className="Separator" />
      <LinkToWebsite className="m-t-m p-r-m p-l-m" t={t} />
    </>
  );
}

OfflineDrawerScreen.propTypes = {
  t: func.isRequired,
};

export default OfflineDrawerScreen;
